import React, { useEffect } from "react";
import {useDropzone} from 'react-dropzone';
import 'bulma/css/bulma.css'
import { FaBackward } from "react-icons/fa";
import useGeolocation from "react-hook-geolocation";

export const PictureSelection : React.FunctionComponent<{files: any, setFiles: (files: any) => void, backToStep1: (e: any) => void }> = (props) => {
    const { files, setFiles, backToStep1 } = props;
    const coordinateOptions = { maximumAge: 0, timeout: 5000, enableHighAccuracy: true };
    const geolocation = useGeolocation(coordinateOptions);

    const {getRootProps, getInputProps, open} = useDropzone({ noClick: true, multiple: true, accept: 'image/*', onDrop: acceptedFiles => {
        setFiles([...files, ...acceptedFiles.map(file => {
          
          console.log(file);
          return Object.assign(file, {
            latitude: geolocation?.latitude,
            longitude: geolocation?.longitude,
            preview: URL.createObjectURL(file)
          })
        })])
      }}
    );

    const thumbs = files.map((file: any) => (
        <figure className="column is-3" key={file.name}>
          <div className="imageWrap">
            <img alt="preview"
                        src={file.preview}
                      />
            <button className={`delete mr-2 deleteButton`} onClick={(e)=> {
              setFiles([...files.filter((f: any) => f.preview !== file.preview)]);
            }} />
          </div>
          <small>Latitude: {file.latitude} - Longitude: {file.longitude}</small>
        </figure>
      ));

    useEffect(() => {
        // Make sure to revoke the data uris to avoid memory leaks
        files.forEach((file: any) => URL.revokeObjectURL(file.preview));
    }, [files]);
  
    return (
        <section>
            <div {...getRootProps({className: 'dropzone'})}>
                <div className="columns is-gapless">
                  <div className="column">
                      <button className="button is-fullwidth" onClick={open}>{files.length === 0 ? (<React.Fragment>Bilder auswählen</React.Fragment>) : (<React.Fragment>Weitere Bilder auswählen</React.Fragment>)}</button>
                  </div>
                  {files.length === 0 && (
                    <div className="column is-3">
                      <a className="button is-fullwidth" onClick={backToStep1}>
                          <FaBackward />&nbsp;<span>Zurück</span>
                      </a>
                    </div>
                  )}
                </div>
                {files.length > 0 && (
                  <React.Fragment>
                    <br />
                    <aside className="columns is-multiline">
                        {thumbs}
                    </aside>
                    <button className="button is-fullwidth">{files.length === 0 ? (<React.Fragment>Bilder auswählen</React.Fragment>) : (<React.Fragment>Weitere Bilder auswählen</React.Fragment>)}</button>
                  </React.Fragment>
                )}
                <input {...getInputProps()} />
            </div>
            
        </section>
    );
  }