import React, { useState } from "react";
import { IGroup } from '../services/AppService'
import { Step2 } from './steps/step2/Step2'
import { GroupSearch } from "./steps/step1/GroupSearch"
import { LocationRequest } from "./steps/step0/LocationRequest"
import appService from '../services/AppService'
import { useMsal } from "@azure/msal-react";
import { UploadProgress } from "./steps/step3/UploadProgress"
import { Step4Action } from "./steps/step4/Step4Action"

export enum UploadStatus {
    NotStarted,
    InProgress,
    Failure,
    Success
}

export interface IUploadProgress {
    message: string,
    status: UploadStatus
}


const recentGroupKey = "recentGroups";

const getRecentGroups = () : IGroup[] =>  {
    try {
        const selectedGroupsStorage = localStorage.getItem(recentGroupKey);
        let groupItems: IGroup[] = selectedGroupsStorage ? JSON.parse(selectedGroupsStorage) : [];
        return groupItems;
    }
    catch (ex)
    {
        console.error(ex);
        return [];        
    }
}

export const Wizard : React.FunctionComponent = () => {
    const { instance } = useMsal();
    const [wizardStep, setWizardStep] = useState<number>(0);
    const [files, setFiles] = useState<any>([]);
    const [fileUploadStatus, setFileUploadStatus] = useState<IUploadProgress[]>([]);
    const [group, setGroup] = useState<IGroup | null>(null);
    const [recentGroups, setRecentGroups] = useState<IGroup[]>(getRecentGroups());
    const [lng, setLng] = useState<number | null>(null);
    const [lat, setLat] = useState<number | null>(null);
    
    const { accounts } = useMsal();
    const currentUserAccount = accounts.length > 0 ? accounts[0].username : "";

    const onSetLocation = (latitude: number, longitude: number) => {
        setLat(latitude);
        setLng(longitude);
        setWizardStep(1);
    }

    const onSelectedGroup = (group: IGroup) => {
        setGroup(group);
        try {
            let groupItems: IGroup[] = getRecentGroups();
            groupItems = [group, ...groupItems.filter(p => p.id !== group.id)];
            groupItems = groupItems.slice(0, 5);
            localStorage.setItem(recentGroupKey, JSON.stringify(groupItems));
        }
        catch (ex) {
            console.log(ex);
        }
        setRecentGroups(getRecentGroups());
        setWizardStep(2);
    }

    const backToStep1 = (e: any) => {
        e.preventDefault();
        setGroup(null)
        setFiles([])
        setWizardStep(1);
    }

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        setWizardStep(3);
        const status: IUploadProgress[] = files.map((f: any, i: number) => {
            const progress: IUploadProgress = {
                message: `Bild ${i + 1}: In der Warteschleife`,
                status: UploadStatus.NotStarted
            }
            return progress
        });
        setFileUploadStatus([...status]);
        for (let i = 0; i < files.length; i++) {
            status[i].message = `Bild ${i + 1}: Wird hochgeladen...`;
            status[i].status = UploadStatus.InProgress;
            setFileUploadStatus([...status]);
            const file = files[i];
            console.log(file)
            try {
                await appService.uploadImage(file, group?.id as string, file?.latitude ?? lat as number, file?.longitude ?? lng as number, instance, currentUserAccount);
                status[i].message = `Bild ${i + 1}: Erfolgreich hochgeladen.`;
                status[i].status = UploadStatus.Success;
                setFileUploadStatus([...status]);
            }
            catch {
                status[i].message = `Bild ${i + 1}: konnte nicht hochgeladen werden.`;
                status[i].status = UploadStatus.Failure;
                setFileUploadStatus([...status]);
            }
        }
        setWizardStep(4);
    }

    let heading = "";
    if (wizardStep === 1)
        heading += "Schritt 1: Baustelle auswählen"
    else if (wizardStep === 2) 
        heading += "Schritt 2: Bilder ansehen oder hochladen"
    else if (wizardStep === 3) 
        heading += "Schritt 3: Bilder werden hochgeladen"
    else if (wizardStep === 4) 
        heading += "Schritt 4: Abgeschlossen"

    return (
        <div className="container">
            <div className="details-contents">
                {heading && (<h1 className="title">{heading}</h1>)}
                {group?.displayName && (<h6 className="title is-6">Baustelle: {group?.displayName}</h6>)}
                {wizardStep === 0 && (
                    <LocationRequest onSetLocation={onSetLocation} />
                )}
                {wizardStep === 1 && (
                    <GroupSearch onSelectedGroup={onSelectedGroup} recentGroups={recentGroups} />
                )}
                {wizardStep === 2 && (
                    <Step2 backToStep1={backToStep1} groupId={group?.id} files={files} handleSubmit={handleSubmit} setFiles={setFiles} />
                )}
                {wizardStep >= 3 && (
                    <React.Fragment>
                        <Step4Action wizardStep={wizardStep} />
                        <UploadProgress fileUploadStatus={fileUploadStatus} />
                        <Step4Action wizardStep={wizardStep} />
                    </React.Fragment>
                )}
            </div>
        </div>
    );
};